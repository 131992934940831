<template>
  <b-container style="margin-top:10em">
    <b-row>
      <b-col cols="2" style="border-right: #495057 1px solid;display:none" id="artigos">
        <b-row class="p-2">
          <h3>
            <b>ARTIGOS MAIS LIDOS</b>
          </h3>
          <hr class="mb-5" />
        </b-row>
        <b-row class="p-2" v-for="item in publicacao" :key="item">
          <router-link to="/" style="text-decoration:none; color:#330667">
            <h5>
              <b>{{item.title}}</b>
            </h5>
          </router-link>
          <p>{{item.desc}}</p>
          <hr class="mb-5" style="color:#CCCCCC;background-color:#CCCCCC; height: 1px" />
        </b-row>
      </b-col>
      <b-col class="text-center mb-5 p-5">
        <h3 style="margin-bottom:3em; color:#330667">
          <b>ARTIGOS PUBLICADOS</b>
        </h3>
        <b-row>
          <b-col>
            <v-card v-for="item in publicacao" :key="item" class="mt-5" elevation="8">
              <v-card-text
                style="color:#495057;
                                                 font-size: large;
                                                 padding:2em"
              >
                <v-row>
                  <v-col cols="2">
                    <v-card elevation="8">
                      <b-link target="_blank" :href="item.title" elevation="10">
                        <img
                          class="corpo"
                          src="@/assets/DOI.png"
                          alt="DOI - Endereço eletrônico da publicação"
                          style="width:60%"
                        />
                      </b-link>
                    </v-card>
                  </v-col>
                  <v-col>{{item.desc}}</v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Publicacoes",
  data() {
    return {
      publicacao: [
        {
          title: "",
          desc: `Ferreira, M.; Azevedo, H.; Mascarello, A.; Segretti, N.; Russo, E.; Russo, V.; Guimarães,C. Discovery of ACH-000143, a novel potent and peripherally preferred melatonin receptor agonist that reduces liver triglycerides and steatosis in diet-induced obese rats. Journal of Medicinal Chemistry. Print ahead. 2021.(Em Publicação)`
        },
        {
          title: "https://pubmed.ncbi.nlm.nih.gov/31763975/",
          desc:
            "Fernandes, T. B.; Segretti, N. D.; Lourenço, F. R.; Cândido, T. M.; Baby, A. R.; Barbosa, E. G.; Parise-Filho,R. Design of Arylsulfonylhydrazones as Potential FabH Inhibitors: Synthesis, Antimicrobial Evaluation and Molecular Docking. Medicinal Chemistry. 2019(E-pubAbstractAheadofPrint)"
        },
        {
          title: "https://pubs.acs.org/doi/10.1021/acs.oprd.8b00017",
          desc:
            "Aguillón, A. R.; Mascarello, A.; Segretti, N. D.; Azevedo, H.; Guimaraes, C. R. W.; de Miranda, L. S. M.; Souza, R. O. M. A. Synthetic Strategies toward SGLT2 Inhibitors. Organic Process Research & Development. 2018, v.22, p467-488"
        },
        {
          title:
            "https://www.sciencedirect.com/science/article/abs/pii/S0960894X16307004",
          desc:
            "Segretti, N. D.; Serafim, R. A.; Segretti, M. C.; Miyata, M.; Coelho, F. R.; Augusto, O. Ferreira, E. I. New antibacterial agents: Hybrids bioisoster derivatives as potential E.coli FabH inhibitors. Bioorganic & Medicinal Chemistry Letters. 2016, v.26, p.3988-3993"
        },
        {
          title:
            "https://www.sciencedirect.com/science/article/abs/pii/S1472979215301177?via%3Dihub",
          desc:
            "Segretti, N. D.; Simoes, C. K.; Correa, M. F.; Felli, V. M. A.; Miyata, M.; Cho, S. H.; Franzblau, S. G.; Fernandes, J. P. S. Antimycobacterial activity of pyrazinoate prodrugs in replicating and non-replicating Mycobacterium tuberculosis. Tuberculosis, 2016, v.99, p.11-16"
        },
        {
          title: "https://pubmed.ncbi.nlm.nih.gov/24433443/",
          desc:
            "Primi, M. C.; Segretti, N. D.; Ferreira, E. I. Medicinal Chemistry of New Compounds to Treat Tuberculosis. Current Clinical Pharmacology. 2015, v.10, p.139-15"
        },
        {
          title: "https://pubmed.ncbi.nlm.nih.gov/24245758/",
          desc:
            "Bueno, R. V.; Braga, R. V.; Segretti, N. D.; Ferreira, E. I.; Trossini, G. H. G.; Andrade,C. H. New Tuberculostatic Agents Targeting Nucleic Acid Biosynthesis: Drug Design using QSAR Approaches. Current Pharmaceutical Design, 2014, v.20, p.4474-4485"
        },
        {
          title:
            "https://www.sciencedirect.com/science/article/abs/pii/S0041010108006302?via%3Dihub",
          desc:
            "Moura, S.; Ultramari, M. A.; de Paula, D. M. L.; Yonamine, M.; Pinto, E. 1H NMR determination of β-N-methylamino-l-alanine (l-BMAA) in environmental and biological samples. Toxicon, 2008, v.53, p.578-583."
        },
        {
          title:
            "http://www.scielo.br/scielo.php?script=sci_arttext&pid=S0103-50532019000701415",
          desc:
            "De Mello, M.; de Oliveira, A.; de Oliveira, C.; Ultramari, M.; Gama, F. Mascarello, A.; Guimarães, C.; de Freitas, M.; Cunha, C.; Lourenço T.; Ferreira, F.; Lopes, J.; Clososki, G. Characterization and in silico Mutagenic Assessment of a New Betahistine Degradation Impurity. Journal of the Brazilian Chemical Society, 2019, v.30, p.1415-1424."
        },
        {
          title:
            "https://www.sciencedirect.com/science/article/abs/pii/S0731708517304880",
          desc:
            "Assafa, J.; Zulkiewicz, D. G.; Wuest, B.; Parr, M. K. Photostability testing using online reactor HPLC hyphenation and mass spectrometric compound identification illustrated by ketoprofen as model compound. Journal of Pharmaceutical and Biomedical Analysis, 2017, v.145, p414-422"
        },
        {
          title:
            "https://www.brazilianjournals.com/index.php/BRJD/article/view/3160",
          desc:
            "Kimura, V. T.; Poço, J. G. P.; Matsubara, R. M. S.; Derenzo, S.; Zulkiewicz, D. G. Marcante, A. Obtaining levulinicacid and other products from sugars using heterogeneous catalysts Brazilian Journal of Development, 2019, v.5, p.14804-14813"
        }
      ]
    };
  }
};
</script>

<style scoped>
hr {
  height: 1em;
  color: #330667;
  background-color: #330667;
  width: 6em;
}
@media only screen and (max-width: 768px) {
  .corpo {
    width: 245% !important;
  }
}
</style>